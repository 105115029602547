import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetService } from "./useGetService";
import { API, DashboardTabs } from "../constants";

// Third party
// import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { usePostService } from "./usePostService";
import { toast } from "react-toastify";

export const useAdminDataGrid = (
  startData: Date | string,
  endDate: Date | string,
  selectedTab: string
) => {
  const { postData, isSubmitting } = usePostService();

  const [rows, setRows] = useState([]);
  const [isRowsLoading, setRowsLoading] = useState(false);

  // API CALL
  const { response, isLoading, refetch } = useGetService(
    `${API.ADMIN.LIST_ARRIVALS_AND_DEPARTURE}?src=${selectedTab}`
  );

  // FETCH ASSIGNMENT
  const {
    response: assignedData,
    isLoading: isAssignedDataLoading,
    refetch: serviceRequestRefetch,
  } = useGetService(`${API.CARNIVAL_ADMIN.GET_SERVICE_REQUEST_BY_VENDOR_ID}`);

  // FETCH SHIPBOARD HR REQUEST DETAILS
  const { response: shipboardAssigned, isLoading: isShipboardAssignedLoading } =
    useGetService(`${API.VENDOR_SERVICE_REQUEST.SHIPBOARD_HR_ARRIVED}s`);

  // FETCH CREATED MANUAL REQUEST DETAILS
  const {
    response: manualRequestDetails,
    isLoading: ismanualRequestDetailsLoading,
  } = useGetService(`${API.VENDOR_SERVICE_REQUEST.CREATE_MANUAL_REQUEST}s`);

  useEffect(() => {
    let interval = setInterval(() => {
      serviceRequestRefetch(
        `${API.CARNIVAL_ADMIN.GET_SERVICE_REQUEST_BY_VENDOR_ID}`
      );
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    setRowsLoading(isLoading || isAssignedDataLoading);
    isLoading === false &&
      response &&
      response.data.length > 0 &&
      setRows(dataHandling(response.data));
  }, [
    response,
    isLoading,
    assignedData,
    isAssignedDataLoading,
    isShipboardAssignedLoading,
    shipboardAssigned,
    manualRequestDetails,
    ismanualRequestDetailsLoading,
  ]);

  const getArrivedInfo = useCallback(
    (x: any) => {
      const data =
        !isShipboardAssignedLoading &&
        shipboardAssigned?.data &&
        shipboardAssigned?.data.find(
          (v: any) => v.tmId === x.FLIGHTDATA.EMP_ID
        );

      return data?.isArrivedToShip === true ? true : false;
    },

    [shipboardAssigned]
  );

  const dataHandling = (resData: any[]) => {
    let newData: any = [];
    setRowsLoading(true);
    if (resData.length > 0) {
      const data = _.cloneDeep(resData);
      data.forEach((x: any) => {
        const y = {
          ...x.FLIGHTDATA,
          ...x.HOTELDATA,
          ...x.PROFILEDATA,
          ...x.Assignments,
          isMeet_and_greetScanned: false,
          isTransportationScanned: false,
          isHotelScanned: false,
          isPortAgent: false,
          isArrivedToShip: getArrivedInfo(x),
          id: x.FLIGHTDATA.EMP_ID, // uuidv4(),
          isManualRequestCreated:
            (!ismanualRequestDetailsLoading &&
              manualRequestDetails?.data &&
              manualRequestDetails?.data.some(
                (v: any) => v.tmId === x.FLIGHTDATA.EMP_ID
              )) ||
            false,
          assignedInfo:
            !isAssignedDataLoading &&
            assignedData?.data &&
            assignedData?.data.find((v: any) => v.tmId === x.FLIGHTDATA.EMP_ID),
        };
        newData.push(y);
      });
    }

    setRowsLoading(false);
    return newData;
  };

  const refetchData = (seltab: string) => {
    setRows([]);
    refetch(`${API.ADMIN.LIST_ARRIVALS_AND_DEPARTURE}?src=${seltab}`);
    // seltab === DashboardTabs.DEPARTURE
    //   ? setRows([]) // refetch(`${API.ADMIN.LIST_ARRIVALS_AND_DEPARTURE}/${seltab}`)
    //   : refetch(`${API.ADMIN.LIST_ARRIVALS_AND_DEPARTURE}/${selectedTab}`);
  };

  const handleArriveClickedCall = async (payload: any) => {
    try {
      const res = await postData(
        API.VENDOR_SERVICE_REQUEST.SHIPBOARD_HR_ARRIVED,
        payload
      );
      toast.success("Acknowledged successfully by shipboard! ");
      const dataIndex = rows.findIndex((x: any) => x.EMP_ID === payload.tmId);
      const currentData = [...rows] as any;
      currentData[dataIndex].isArrivedToShip = res.data.isArrivedToShip;
      setRows(currentData);
    } catch (err) {
      console.error(err);
      toast.error("Failed!");
    }
  };

  const handleCreateManualRequest = (resInfo: any) => {
    const data = [...rows];
    data.map((x: any) => {
      x.isManualRequestCreated =
        x.isManualRequestCreated === false
          ? resInfo.data.some((y: any) => y.tmId === x.EMP_ID)
          : x.isManualRequestCreated;
      return x;
    });
    setRows(data);
    console.log("handleCreateManualRequest", data, resInfo);
  };

  const transportAssignedCount = useMemo(
    () =>
      (!isAssignedDataLoading &&
        assignedData?.data &&
        assignedData.data.length) ||
      0,
    [assignedData]
  );

  const shipboardArrivedCount = useMemo(
    () =>
      (!isShipboardAssignedLoading &&
        shipboardAssigned?.data &&
        shipboardAssigned.data.length) ||
      0,
    [shipboardAssigned]
  );

  return {
    rows,
    isRowsLoading,
    refetchData,
    transportAssignedCount,
    shipboardArrivedCount,
    handleArriveClickedCall,
    handleCreateManualRequest,
  };
};
